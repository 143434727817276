
















































import {Component, Vue} from "vue-property-decorator";
import Tree from "@/components/tree/Tree.vue";
import {TreeModel} from "@/components/tree/TreeModel";
import {CustomerGroupService} from "@/views/customer-group/CustomerGroupService";
import {ActionResult} from "@/models/ActionResult";
import vSelect from 'vue-select'

@Component({
    components: {
        Tree,
        vSelect
    }
})
export default class CustomerGroupList extends Vue {
    searchTerm = '';
    listCustomerGroup: TreeModel[] = [];
    customerGroupService = new CustomerGroupService();
    selectedItems: [] = [];

    deleteResults: ActionResult[] = [];
    selectedItem: any;
    templates: Array<any> = [
        {id: "TGB", name: "TGB"},
        {id: "BTGS", name: "FLEXIUS"}
    ];

    isShowPermission = false;

    beforeMount() {
        this.search();
    }

    onItemAdded(data: any) {
        this.customerGroupService.insert(data.parentId, data.value)
            .then((result: ActionResult) => {
                if (result.code > 0) {
                    this.search();
                }
            })
            .catch(e => this.$vs.notify({
                title: '',
                text: e.message,
                color: 'danger'
            }));
    }

    onUpdatedId(item: any) {
        this.customerGroupService.updateId(item.id, item.parentId, item.value, item.data.concurrencyStamp, item.data.idSaleIG, item.data.idReturnIG, item.data.erpCode, item.data.printTemplate)
            .then((result: ActionResult) => {
                if (result.code > 0) {
                    this.search();
                }
            })
            .catch((error: ActionResult) => {
                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: 'danger'
                });
            });
    }

    onItemUpdated(item: any) {
        this.customerGroupService.update(item.id, item.parentId, item.value, item.data.concurrencyStamp)
            .then((result: ActionResult) => {
                if (result.code > 0) {
                    this.search();
                }
            })
            .catch((error: ActionResult) => {
                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: 'danger'
                });
            });
    }

    onItemRemoved(item: any) {
        this.selectedItem = item;
        const deleteMessage = this.$t('Customer group');
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: this.$t('Confirm delete'),
            acceptText: this.$t('Accept'),
            cancelText: this.$t('Cancel'),
            text: this.$t('ConfirmDeleteMessage', [deleteMessage, item.name]),
            accept: this.remove
        });
    }

    onItemSelected(items: any) {
        this.selectedItems = items;
    }

    onViewChange(item: TreeModel) {
        const data = item.data;
        if (data) {
            this.customerGroupService.updateViewStatus(data.id, data.isView)
                .then((result: ActionResult) => {
                    if (result.code > 0) {
                        this.search();
                    }
                })
                .catch((error: ActionResult) => {
                    this.$vs.notify({
                        title: '',
                        text: error.message,
                        color: 'danger'
                    });
                });
        }
    }

    onApproveChange(item: TreeModel) {
        const data = item.data;
        if (data) {
            this.customerGroupService.updateApproveStatus(data.id, data.isApprove)
                .then((result: ActionResult) => {
                })
                .catch((error: ActionResult) => {
                    data.isApprove = !data.isApprove;
                    this.$vs.notify({
                        title: '',
                        text: error.message,
                        color: 'danger'
                    });
                });
        }
    }

    async search() {
        try {
            this.openLoading(true);
            this.listCustomerGroup = await this.customerGroupService.search(this.searchTerm);
            this.openLoading(false);
        } catch (e) {
            this.openLoading(false);
            this.listCustomerGroup = [];
        }
    }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading()
        } else {
            this.$vs.loading.close();
        }
    }

    private remove() {
        this.customerGroupService.delete(this.selectedItem.id)
            .then((result: ActionResult) => {
                if (result.code > 0) {
                    this.$vs.notify({
                        title: '',
                        text: result.message,
                        color: 'success'
                    });
                    this.search();
                }
            })
            .catch((error: ActionResult) => {
                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: 'danger'
                });
            });
    }
}
